<template>
  <div class="ExamTaskRules">
    <detailsHeader
      :query="$route.query"
      :router-url="$route.query.fromUrl || 'ExamCorrectTask'"
    >
      <!-- <el-button type="primary" plain @click="dialogVisible = true"
        >新增学校分组</el-button
      > -->
    </detailsHeader>
    <div class="tab-box">
      <div>
        <div class="filter-box">
          <div class="filter-item">
            选择设置题目：
            <el-select
              v-model="sheetDetailId"
              placeholder="请选择"
              @change="exammarkPoint"
            >
              <el-option
                v-for="item in questionListData"
                :key="item.id"
                :label="item.examQuestionNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>

            分值：<span v-if="questionListData.length > 0">{{
              questionListData.filter((item) => item.id == sheetDetailId)[0]
                .score
            }}</span
            >分
          </div>
          <div class="filter-item">
            <i class="el-icon-warning" @click="showModel()"></i>给分方式：
            <el-select
              v-model="scoreType"
              placeholder="请选择"
              :disabled="getDis()"
              @change="setList()"
            >
              <el-option
                v-for="(item, index) in scoreTypeItem"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="filter-item">
            评分规则：
            <el-select
              v-model="indexQuestion.ruleScore"
              placeholder="请选择"
              :disabled="getDis()"
              @change="editRules()"
            >
              <el-option
                v-for="(item, index) in scoreRulesList"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="filter-item">
            添加
            <el-input
              v-model="questionNums"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder="请输入"
              @blur="questionNums = $event.target.value"
            ></el-input>
            给分项

            <el-button
              type="primary"
              :disabled="getDis()"
              @click="addList(questionNums)"
              >确定</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          border
          :data="exammarkPointList"
          style="width: 100%; margin-top: 24px"
        >
          <el-table-column prop="name" label="名称" width="300" align="center">
            <template slot-scope="scope">
              <template v-if="questionListData.length > 0">
                {{
                  questionListData.filter((item) => item.id == sheetDetailId)[0]
                    .examQuestionNumber
                }}
              </template>
              -
              <el-input
                v-model="scope.row.name"
                class="list-input"
                placeholder=""
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="分值" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.score"
                class="list-input"
                placeholder=""
                oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,1})?/g) ?? [""])[0]'
                @blur="scope.row.score = $event.target.value"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="步长" align="center" width="500">
            <!-- points -->
            <div slot-scope="{ row }" class="points-box">
              <div>
                <el-radio-group v-model="row.step" @change="setPoints(row)">
                  <el-radio label="2">自定义</el-radio>
                  <el-radio label="1">设置步长</el-radio>
                </el-radio-group>
              </div>
              <div class="input-box">
                <el-input
                  v-model="row.markScore"
                  placeholder=""
                  style="width: 280px"
                  :disabled="row.step == 1"
                  oninput="value=value.replace(/[^0-9.,，]/g,'')"
                  @blur="row.markScore = $event.target.value"
                ></el-input>
                <div style="margin-top: 4px">
                  <el-input
                    v-model="row.stepValue"
                    class="stepValue"
                    placeholder=""
                    oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,1})?/g) ?? [""])[0]'
                    :disabled="row.step == 2"
                    @blur="row.stepValue = $event.target.value"
                  ></el-input>
                  <!-- <el-button
                    type="primary"
                    :disabled="row.step == 1"
                    @click="setPoints(row)"
                  >
                    生成
                  </el-button> -->
                </div>
              </div>

              <!-- <el-input v-model="scope.row.points" placeholder=""></el-input>
              <span>步长：</span>

              <el-input
                v-model="scope.row.stepValue"
                class="stepValue"
                placeholder=""
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @blur="scope.row.stepValue = $event.target.value"
              ></el-input>
              <el-button type="primary" @click="setPoints(scope.row)"
                >生成</el-button
              > -->
            </div>
          </el-table-column>
          <!-- <el-table-column
            v-if="indexQuestion.ruleScore != 1"
            prop="name"
            label="多评仲裁"
            align="center"
          >
            <template slot="header">
              多评仲裁
              <el-button
                class="set-btn"
                plain
                icon="el-icon-setting"
                @click="showSet()"
              >
                批量设置
              </el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="text" @click="showSet(scope.row)">
                <template v-if="scope.row.errorScore !== null">
                  误差分：{{ scope.row.errorScore }}
                  <br />
                  <br />
                  {{ scope.row.scaleType | setName(decimalDigits) }},
                  {{ scope.row.carryType | setName(digitsMethod) }}
                </template>
                <template v-else>设置</template>
              </el-button> -->
          <!-- <el-input 
                v-model="scope.row.errorScore"
                class="list-input"
                placeholder=""
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @blur="scope.row.errorScore = $event.target.value"
              ></el-input> -->
          <!-- </template>
          </el-table-column> -->
          <el-table-column
            v-if="scoreType == 1"
            prop="name"
            label="打分区域"
            width="380"
            align="center"
          >
            <template slot-scope="{ row }">
              <div class="score-img-box">
                <div v-if="row.pointsUrl" class="img-box" @click="show(row)">
                  <img class="imgsn" :src="row.pointsUrl" alt="" />
                </div>

                <el-button v-if="row.pointsUrl" type="text" @click="show(row)"
                  >预览</el-button
                >
                <el-button
                  type="text"
                  :disabled="indexQuestion.hasMark > 0"
                  @click="setArea(row)"
                  >设置打分区域</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                :disabled="indexQuestion.hasMark > 0"
                @click="clearTips(scope.row)"
              >
                清空
              </el-button>
              <el-button
                type="text"
                style="color: #d92020"
                :disabled="indexQuestion.hasMark > 0"
                @click="delitemTips(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="questionListData.length > 0" class="add-row">
          <template v-if="!getDis()">
            试题分值：
            <span>
              {{
                questionListData.filter((item) => item.id == sheetDetailId)[0]
                  .score
              }}</span
            >
            分
          </template>
          <template v-else>
            <template
              v-if="
                questionListData.length > 0 &&
                questionListData.filter((item) => item.id == sheetDetailId)[0]
                  .isOption == 1
              "
            >
              选做题不能设置分项给分
            </template>
            <template v-else>
              此题
              <!-- {{ indexQuestion }} -->
              <!-- <span
                v-if="
                  (indexQuestion.scaleType != 3 ||
                    indexQuestion.carryType != 1) &&
                  indexQuestion.ruleScore != 1
                "
                class="err"
              >
                不是保留两位小数-四舍五入
              </span> -->
              <span v-if="indexQuestion.hasMark > 0" class="err">
                已有老师批阅
              </span>

              <span v-if="indexQuestion.isBindQuestion > 0" class="err">
                是绑定批阅
              </span>
            </template>
          </template>
        </div>
      </div>
      <div class="btn-box">
        <el-button type="primary" :disabled="getDis()" @click="addList(1)">
          添加给分项
        </el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          :disabled="getDis() || exammarkPointList.length < 2"
          @click="submit()"
          >保存</el-button
        >
      </div>
      <div class="tips">
        <p>注意事项：</p>
        <p>
          1.1、自由打分：可直接点击打分板进行打分，所打分值不在图片上呈现。可用于分步骤打分。<br />
          1.2、区域打分：可点击预打分区域，所打分值将呈现在图片上。可用于大题多小问或填空题。<br />
          2、绑定批阅的普通题目无法设置分项给分。<br />
          3、若题目的多评规则不是“保留两位小数-四舍五入”，则无法为该题设置为分项给分。<br />
          4、若已有教师批阅记录，则无法删除分项给分设置，也无法切换给分方式。<br />
          5、若已开始阅卷后，则无法调整分项给分设置。<br />
          6、各分项最大值之和不得超过本题总分。<br />
        </p>
      </div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="url"
    />
    <scoreRoles ref="scoreRoles"></scoreRoles>
    <model ref="model"></model>
  </div>
</template>

<script>
// import ExamDetailsHeader from "./component/ExamDetailsHeader";
import detailsHeader from "@/components/detailsHeader.vue";
import scoreRoles from "./components/scoreRoles.vue";
import model from "./components/model.vue";
import { getObj } from "@/core/api/exam/exam";
import {
  questionList,
  exammarkPoint,
  delExammarkPoint,
  saveExammarkPoint,
  questionDetail,
  exammarkRuleScore,
  pointArea,
} from "@/core/api/exam/examCorrectTask";
// , removeStore
import { setStore, getStore } from "@/core/util/store";
import {
  scoreTypeItem,
  scoreRulesList,
  decimalDigits,
  digitsMethod,
} from "@/core/util/constdata";
export default {
  name: "ExamTaskRules",
  components: {
    detailsHeader,
    model,
    scoreRoles,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      indexQuestion: {},
      scoreTypeItem: scoreTypeItem,
      decimalDigits: decimalDigits,
      digitsMethod: digitsMethod,
      scoreRulesList: scoreRulesList,
      examInformation: {},
      examInfo: {},
      sheetDetailId: "",
      questionNums: 2,
      scoreType: 2,
      ruleScore: 1,
      questionListData: [],
      exammarkPointList: [],
      btnLoading: false,
      loading: false,
      showViewer: false, // 显示查看器
      url: [], // url地址
    };
  },
  created() {
    this.loading = true;
    this.examInfo = this.$route.query;

    this.init();
    this.getObj();
    this.examInfo.name = this.examInfo.examName;
  },
  methods: {
    showModel() {
      this.$refs.model.init();
    },
    setList() {
      this.exammarkPointList = this.exammarkPointList.map((item) => {
        item.type = this.scoreType;
        return item;
      });
    },
    setNewList(row) {
      this.exammarkPointList = this.exammarkPointList.map((item) => {
        if (item.tId == row.tId) {
          Object.assign(item, row);
        }
        if (!row.tId) {
          Object.assign(item, row);
        }
        return item;
      });
      this.exammarkPointList = JSON.parse(
        JSON.stringify(this.exammarkPointList)
      );
      // console.log(JSON.parse(JSON.stringify(this.exammarkPointList)));
    },
    showSet(row) {
      if (this.exammarkPointList.length == 0) {
        this.$message({
          showClose: true,
          message: "请先添加给分项",
          type: "warning",
        });
        return;
      }
      if (!row) {
        row = {
          questionNum: this.exammarkPointList[0].questionNum,
          errorScore: null,
          markScore: "",
          carryType: "",
          scaleType: "",
        };
      }
      // console.log(row);
      this.$refs.scoreRoles.init(row);
    },
    exammarkRuleScore(arr) {
      // type 1 评分规则 2 给分方式
      let data = {
        examPaperId: this.examInfo.paperId,
        type: 1,
        ruleScore: arr,
      };

      this.btnLoading = true;
      exammarkRuleScore(data)
        .then(() => {
          this.btnLoading = false;
          // 更新本地数据
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    editRules() {
      let indexQuestion = this.questionListData.filter(
        (item) => item.id == this.sheetDetailId
      )[0];
      let data = {
        questionNum: indexQuestion.questionNum,
        errorScore: 0,
        scaleType: 1,
        carryType: 1,
        ruleScore: this.indexQuestion.ruleScore,
        markType: indexQuestion.markType,
        sheetDetailId: indexQuestion.id,
      };
      // examPaperId: this.examInfo.paperId,
      // console.log(data);
      this.exammarkRuleScore([data]);
    },
    show(row) {
      if (!row.pointsUrl) return;
      this.url = [row.pointsUrl];
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    init() {
      let questionId = getStore({ name: "questionId", type: 1 });
      if (questionId) {
        this.sheetDetailId = Number(questionId);
      }
      let question = getStore({ name: "question", type: 1 });

      if (question) {
        this.sheetDetailId = Number(question.id);
        this.pointId = getStore({
          name: "tId",
          type: 1,
        });
        let exammarkPointItem = getStore({
          name: "exammarkPointItem",
          type: 1,
        });

        if (exammarkPointItem && exammarkPointItem.length == 0) {
          this.questionList(1);
        } else {
          this.questionList();

          this.exammarkPointList = exammarkPointItem;
          this.exammarkPointList.map((item) => {
            this.scoreType = item.type;
          });
        }
      } else {
        this.questionList(1);
      }
    },

    async setArea(row) {
      const res = await pointArea({ sheetDetailId: this.sheetDetailId });
      // console.log(res);
      if (!res.data.data.path) {
        this.$message({
          message: `当前试题无裁切图片，请重新裁切试卷后再试`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      let question = this.questionListData.filter(
        (item) => item.id == this.sheetDetailId
      )[0];
      setStore({ name: "question", type: 1, content: question });
      setStore({
        name: "exammarkPointItem",
        type: 1,
        content: this.exammarkPointList,
      });
      setStore({
        name: "tId",
        type: 1,
        content: row.tId,
      });
      this.$router.push({
        name: "ExamQuestionArea",
        query: this.$route.query,
      });
    },
    //获取考试基本信息
    getObj() {
      getObj(this.examInfo.examId).then((res) => {
        this.examInformation = res.data.data;
      });
    },
    getDis() {
      let type = false;
      // if (this.indexQuestion.ruleScore != 1) {
      //   if (this.indexQuestion.carryType != 1) {
      //     type = true;
      //   }
      //   if (this.indexQuestion.scaleType != 3) {
      //     type = true;
      //   }
      // }

      if (this.indexQuestion.hasMark > 0) {
        type = true;
      }
      if (this.indexQuestion.isBindQuestion > 0) {
        type = true;
      }
      let isOption = this.questionListData.filter(
        (item) => item.id == this.sheetDetailId
      );
      if (isOption.length > 0 && isOption[0].isOption == 1) {
        type = true;
      }
      return type;
    },
    submit() {
      // if (this.indexQuestion.ruleScore != 1) {
      //   this.$message({
      //     showClose: true,
      //     message: "双评仲裁暂时不支持分项给分，请修改后重试！",
      //     type: "error",
      //   });
      //   return;
      // }
      let type = false;
      let step = false;
      let stepArr = [];
      let nameArr = [];
      let errorScore = [];
      let errorScoreItem = [];
      let str = "";
      let score = 0;
      let indexQuestion = this.questionListData.filter(
        (item) => item.id == this.sheetDetailId
      )[0];
      let noPoints = [];
      this.exammarkPointList.map((index, i) => {
        index.sheetDetailId = this.sheetDetailId;
        if (index.errorScore > index.score) {
          errorScoreItem.push(i + 1);
        }
        if (index.errorScore === null && this.indexQuestion.ruleScore != 1) {
          errorScore.push(i + 1);
        }
        if (index.markScore) {
          index.markScore = index.markScore.toString();
          index.markScore = index.markScore.replaceAll("，", ",");
        }
        if (!index.points.width && this.scoreType == 1) {
          noPoints.push(i + 1);
        }
        if (this.scoreType == 2) {
          index.points = {};
          index.pointsUrl = "";
        }

        if (!index.name) {
          type = true;
          nameArr.push(i + 1);
        }

        score = score + Number(index.score);
        if (index.step == 2) {
          if (!index.markScore) {
            step = true;
            stepArr.push(i + 1);
            return;
          }
          let re = /^([0-9]|[1-9]\d+)(\.\d{1})?$/;
          let markScoreList = index.markScore.split(",");
          markScoreList.forEach((item) => {
            // console.log(re.test(item));
            if (!re.test(item) || item > Number(index.score)) {
              step = true;
              stepArr.push(i + 1);
            }
          });
        } else {
          if (Number(index.step) > Number(index.score)) {
            step = true;
            stepArr.push(i + 1);
          }
        }
      });

      if (nameArr.length > 0) {
        this.$message({
          message: `请输入第${nameArr.join(",")}项名称`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (noPoints.length > 0 && this.scoreType == 1) {
        this.$message({
          message: `请设置第${noPoints.join(",")}项打分区域`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      // if (errorScoreItem.length > 0) {
      //   this.$message({
      //     message: `第${errorScoreItem.join(",")}项误差分大于小题总分`,
      //     type: "warning",
      //     showClose: true,
      //   });
      //   return;
      // }
      // if (errorScore.length > 0) {
      //   this.$message({
      //     message: `第${errorScore.join(",")}项误差分未设置`,
      //     type: "warning",
      //     showClose: true,
      //   });
      //   return;
      // }
      if (indexQuestion.score != score) {
        this.$message({
          message: "分项分数之和必须等于小题总分",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (type) {
        this.$message({
          message: str,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (step) {
        this.$message({
          message: `第${stepArr.join(",")}项步长无效`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.saveExammarkPoint();
    },
    saveExammarkPoint() {
      this.btnLoading = true;
      let array = JSON.parse(JSON.stringify(this.exammarkPointList));
      array = array.map((item) => {
        if (item.type == 1) {
          item.points = JSON.stringify(item.points);
        } else {
          item.points = "";
        }
        if (item.step == 1) {
          item.markScore = item.stepValue;
        }
        return item;
      });
      saveExammarkPoint(array)
        .then(() => {
          setStore({
            name: "exammarkPointItem",
            type: 1,
            content: this.exammarkPointList,
          });
          this.$confirm(
            "保存成功，开始阅卷后无法调整分项给分相关设置，请仔细检查！",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            }
          )
            .then(() => {})
            .catch(() => {});

          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setPoints(item) {
      if (item.step == 1) {
        // if (item.oldStep) {
        //   item.markScore = item.oldStep;
        // } else {
        //   item.markScore = 1;
        // }
      } else {
        if (!Number(item.score)) {
          this.$message({
            message: "请先设置有效分数",
            type: "warning",
            showClose: true,
          });
          return;
        }
        if (
          !Number(item.stepValue) ||
          Number(item.stepValue) > Number(item.score)
        ) {
          this.$message({
            message: "请输入有效步长",
            type: "warning",
            showClose: true,
          });
          return;
        }
        let arr = [];
        let number = "";

        number = Number(item.score);
        for (let i = 0; i <= parseInt(number / item.stepValue); i++) {
          arr.push((i * (item.stepValue * 10)) / 10);
        }
        if (item.score % item.stepValue > 0) {
          arr.push(item.score);
        }
        // console.log(arr);
        item.markScore = arr.join(",");
      }
    },
    addList(num) {
      if (!num) return;
      for (let i = 0; i < num; i++) {
        //           questionNum: this.questionNum,
        let data = {
          tId: new Date().getTime() + i + 1,
          examPaperId: this.examInfo.paperId,
          name: "",
          points: {},
          score: "",
          errorScore: null,
          stepValue: 1,
          markScore: "",
          carryType: "",
          scaleType: "",
          type: this.scoreType,
          step: "1",
          sheetDetailId: this.sheetDetailId,
        };
        data.questionNum = this.questionListData.filter(
          (item) => item.id == this.sheetDetailId
        )[0].questionNum;
        this.exammarkPointList.push(data);
      }
    },
    clearTips(row) {
      const h = this.$createElement;
      let str;
      this.exammarkPointList.map((item, index) => {
        if (row.tId == item.tId) {
          str = item.name ? item.name : `给分点${index + 1}`;
        }
      });
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h("span", null, "当前正在清空 "),
          h("span", { style: "color: red" }, str),
          h("span", null, "填写数据，清空后不可恢复,是否继续？ "),
        ]),
        showCancelButton: true,
        confirmButtonText: "继续",
        cancelButtonText: "取消",
      })
        .then(() => {
          // this.clearList(row);
          row.name = "";
          row.score = "";
          row.step = "1";
          row.markScore = "";
          row.pointsUrl = "";
          row.scaleType = "";
          row.carryType = "";
          row.errorScore = null;
          setStore({
            name: "exammarkPointItem",
            type: 1,
            content: this.exammarkPointList,
          });
        })
        .catch(() => {});
    },
    // clearList(row){},
    delitemTips(row) {
      if (this.exammarkPointList.length == 2) {
        this.$message({
          showClose: true,
          message: "给分点数量不得少于2条",
          type: "error",
        });
        return;
      }
      const h = this.$createElement;
      let str;
      this.exammarkPointList.map((item, index) => {
        if (row.tId == item.tId) {
          str = item.name ? item.name : `给分点${index + 1}`;
        }
      });
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h("span", null, "当前正在删除 "),
          h("span", { style: "color: red" }, str),
          h("span", null, "是否继续？ "),
        ]),
        showCancelButton: true,
        confirmButtonText: "继续",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.delList(row, instance, done);
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.exammarkPointList.map((item, index) => {
            if (row.tId == item.tId) {
              str = item.name ? item.name : `给分点${index + 1}`;
            }
          });
        })
        .catch(() => {});
    },
    delList(item, instance, done) {
      if (!item.id) {
        this.exammarkPointList = this.exammarkPointList.filter(
          (index) => index.tId != item.tId
        );
        instance.confirmButtonLoading = false;
        done();
      } else {
        delExammarkPoint({ id: item.id })
          .then(() => {
            this.exammarkPointList = this.exammarkPointList.filter(
              (index) => index.tId != item.tId
            );
            done();
            instance.confirmButtonLoading = false;
          })
          .catch(() => {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "继续";
          });
      }
      // this.exammarkPointList = [...this.exammarkPointList];
    },
    questionList(type) {
      let data = {
        examPaperId: this.examInfo.paperId,
      };
      questionList(data)
        .then((res) => {
          this.questionListData = res.data.data;

          if (type) {
            if (!this.sheetDetailId) {
              this.sheetDetailId = res.data.data[0].id;
            }

            this.exammarkPoint();
          } else {
            this.loading = false;
          }

          this.questionDetail();
        })
        .catch(() => {});
    },
    questionDetail() {
      let data = {
        examPaperId: this.examInfo.paperId,
        sheetDetailId: this.sheetDetailId,
      };
      questionDetail(data)
        .then((res) => {
          this.indexQuestion = res.data.data;
        })
        .catch(() => {});
    },
    exammarkPoint() {
      this.indexQuestion = {};
      setStore({
        name: "questionId",
        type: 1,
        content: this.sheetDetailId,
      });
      this.questionDetail();
      let data = {
        examPaperId: this.examInfo.paperId,
        sheetDetailId: this.sheetDetailId,
      };
      this.loading = true;
      exammarkPoint(data)
        .then((res) => {
          let array = JSON.parse(JSON.stringify(res.data.data));
          array = array.map((item) => {
            item.tId = item.id;
            if (item.type == 1) {
              item.points = JSON.parse(item.points);
            }
            if (item.step == 2) {
              item.stepValue = 1;
              // item.markScore = "";
            } else {
              item.stepValue = Number(item.markScore);
              item.markScore = "";
            }
            this.scoreType = item.type;
            return item;
          });
          this.exammarkPointList = array;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamTaskRules {
  padding-top: 24px;

  .btn-box {
    margin-top: 24px;
    text-align: center;
  }
  .tab-box {
    padding: 24px;
    margin-top: 24px;
    background-color: #ffffff;
    .filter-box {
      display: flex;
      .filter-item {
        margin-right: 24px;
        i {
          color: #2474ed;
          margin-right: 3px;
          cursor: pointer;
        }
      }
      .el-input {
        width: 80px;
      }
    }
    .list-input {
      width: 120px;
    }
    .points-box {
      display: flex;
      align-items: center;
      padding: 17px 12px 0 12px;
      justify-content: center;
      .input-box {
        text-align: left;
      }
      .el-radio {
        display: block;
        margin-left: 0;
        text-align: left;
        margin-bottom: 0;
        &:first-child {
          margin-bottom: 22px;
        }
      }
      .el-button {
        margin-left: 12px;
      }
      span {
        flex-shrink: 0;
        margin-left: 24px;
      }
      .stepValue {
        width: 80px;
      }
    }
    .add-row {
      border: 1px solid #ebeef5;
      border-top: 0;
      text-align: center;
      padding: 9px;
      > span {
        color: $primary-color;
      }
      .err {
        color: #d92020;
      }
      .el-button {
        margin-left: 40px;
      }
    }
  }
  .score-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-box {
    width: 140px;
    height: 40px;
    overflow: hidden;
    margin: 0 10px;
    background-color: #dddddd;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .tips {
    padding: 10px;
    background: #fff3f3;
    border-radius: 4px;
    margin-top: 24px;
    // p {
    //   &:first-child {
    //     position: relative;
    //     &::after {
    //       content: "!";
    //       position: absolute;
    //       left: -20px;
    //       top: 4px;
    //       width: 12px;
    //       height: 12px;
    //       line-height: 12px;
    //       font-size: 12px;
    //       text-align: center;
    //       color: #ffffff;
    //       letter-spacing: 0;
    //       background: #d92020;
    //       border-radius: 50%;
    //     }
    //   }
    // }
  }
}
</style>
