<template>
  <div class="model">
    <el-dialog
      title="给分方式说明"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="660px"
    >
      <div class="tips">
        <span>【自由打分】</span
        >：教师可以直接点击打分器对应分值进行打分，所打分值不在图片上呈现。
      </div>
      <img src="@/assets/exam/task/score.png" alt="" />
      <div class="tips">
        <span>【区域打分】</span
        >：支持点击打分区域，所打分值将呈现在图片上。可用于大题多小问或填空题。
      </div>
      <img src="@/assets/exam/task/are_score.png" alt="" />
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Model",
  components: {},
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.model {
  font-size: 14px;
  .tips {
    margin-bottom: 4px;
    white-space: nowrap;
    span {
      color: #2474ed;
    }
  }
}
</style>
